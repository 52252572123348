<template>
  <div id="print">
    <p style="text-align: center; font-weight: 600">开票通知单</p>
    <!--    <p style="width: 500px; float: left;">客户名称：奥吉总经办</p>-->
    <!--    <p style="width: 400px; float: right; text-align: right">出库单号：OGJXC20210400118</p>-->
    <!--    <div style="width: 100%; float: right">-->
    <!--      <span-->
    <!--        style="float: right; width: 400px; text-align: right">-->
    <!--        出库日期：2014-1-1-->
    <!--      </span>-->
    <!--    </div>-->
    <div style="margin-bottom: 10px">
      <a-row>
        <a-col :span="8">
          客户名称：奥吉总经办
        </a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          出库单号：OGJXC20210400118
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
        </a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          出库日期：2014-1-1
        </a-col>
      </a-row>
    </div>
    <div>
      <table>
        <tbody>
          <tr>
            <th>货物名称</th>
            <th>规格</th>
            <th>数量</th>
            <th>单位</th>
            <th>单价</th>
            <th>金额</th>
            <th>备注</th>
          </tr>
          <tr v-for="item in orderList" :key="item.id">
            <td>测试</td>
            <td>1</td>
            <td>1</td>
            <td>瓶</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
          </tr>
          <tr>
            <td>合计</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
          </tr>
          <tr class="h100">
            <td>备注</td>
            <td colspan="6"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <p id="footer"><span>开票人：{{ userInfo.name }}</span><span>申请人：张洁</span><span>部门经理：张海新</span><span>申请时间：{{ date }}</span></p></div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'SaleOutStockBill',
  components: {},
  props: {
    currentData: {
      type: Object,
      default: null
    },
    balanceData: {
      type: Array,
      default: null
    }
  },
  created() {
    this.initData()
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val
    },
    balanceData: {
      handler(val, oldVal) {
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      orderData: {},
      orderList: [],
      userInfo: this.$store.getters.userInfo,
      date: moment().year() + '-' + (moment().month() + 1) + '-' + moment().date()
    }
  },
  methods: {
    initData() {
      this.orderData = this.currentData
      this.orderList = this.balanceData
    },
    moneyFormatter(money) {
      if (typeof money !== 'number') {
        return ''
      }
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 100px auto;
}
@media print {
  html, body {
    height: inherit;
  }
}
.title{
  width: 100%;
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
#print { width: 80%; margin: 0 auto; padding-top: 50px }
#print p, #print table { width: 100% }
table { border-collapse: collapse }
.h100 { height:100px }
td, th { padding: 5px; border: 1px solid #ccc;text-align: center; }
td:nth-child(1), td:nth-child(2) { text-align: center }
td:nth-child(5) { text-align: right }
td:nth-child(6) { text-align: right }
#footer span { display: inline-block; width: 20% }
#footer span:last-child { width: 30% }
</style>
